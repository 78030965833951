@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: KlavikaMed;
    src: url("../../public/assets/fonts/klavika-medium.otf") format("opentype");
    src: url("../../public/assets/fonts/klavika-medium.ttf") format("truetype"); 
}

[x-cloak] {
    display: none !important;
}

* {
    @apply font-calibri font-normal;
}

body {
    @apply bg-sp-white;
    @apply text-sp-primary;
}

*::-moz-selection {
    @apply bg-sp-blue
}

*::selection {
    @apply bg-sp-blue
}

@layer components {
    h1 {
        @apply text-5xl mb-8 font-klavika-med break-words
    }

    h1.blue {
        @apply text-sp-blue font-klavika-med break-words
    }

    h1.hero-title {
        @apply text-5xl md:text-6xl mb-8 font-klavika-med break-words
    }

    h2 {
        @apply text-4xl mb-3 font-klavika-med
    }

    h2.blue {
        @apply text-sp-blue font-klavika-med break-words
    }

    h2.hero-title {
        @apply text-5xl mb-8 font-klavika-med break-words
    }

    h3 {
        @apply text-3xl mb-3 font-klavika-med
    }

    h4 {
        @apply text-2xl mb-3 font-klavika-med
    }

    h5 {
        @apply text-xl mb-2 font-klavika-med
    }

    .custom-kt h4 {
        @apply block text-2xl mt-6 mb-4 text-sp-primary font-klavika-med font-medium uppercase
    }

    .custom-kt h5 {
        @apply block text-xl mt-6 mb-4 text-sp-blue font-klavika-med font-medium
    }

    .custom-kt h6 {
        @apply block text-xl mt-6 mb-4 text-sp-primary font-klavika-med font-medium
    }

    h6 {
        @apply text-lg mb-2 font-klavika-med
    }

    p {
        @apply text-base font-medium mb-4
    }

    a, .custom-kt a {
        @apply hover:text-sp-blue
    }

    a.blue, .custom-kt a {
        @apply text-sp-blue font-semibold
    }

    ul {
        @apply list-disc pl-5
    }

    ol {
        @apply list-decimal pl-5
    }

    .radius-top {
        border-top-left-radius: 90px;
        border-top-right-radius: 90px;
    }

    .radius-all {
        border-radius: 90px;
    }

    .radius-top-sm {
        border-top-left-radius: 50px;
        border-top-right-radius: 50px;
    }

    .btn-white {
        @apply bg-sp-white text-sp-primary inline-block py-3 px-5 rounded-full transition duration-300 hover:shadow-md hover:shadow-sp-secondary
    }

    .btn-blue {
        @apply bg-sp-blue text-sp-white inline-block py-3 px-5 rounded-full transition duration-300 hover:shadow-md hover:shadow-sp-secondary
    }

    .btn-blue.active, .btn-white.active {
        @apply bg-sp-white text-sp-blue shadow-sm shadow-sp-secondary hover:text-sp-blue hover:shadow-md hover:shadow-sp-secondary
    }

    .shake {
        animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    }

    @keyframes shake {
        10%, 90% {
          transform: rotate(-1deg);
        }
        20%, 80% {
          transform: rotate(1deg);
        }
        
        30%, 50%, 70% {
          transform: rotate(-1deg);
        }
        40%, 60% {
          transform: rotate(1deg);
      }
    }

    .circular-progress{
        background: conic-gradient(#12A1E3 3.6deg, #d0edfb 0deg);
    }
    .circular-progress::before{
        content: "";
        position: absolute;
        height: 60px;
        width: 60px;
        border-radius: 50%;
        background-color: #fff;
    }
    .progress-value{
        position: relative;
        font-size: 24px;
        font-weight: 500;
        font-family: KlavikaMed;
        color: #12A1E3;
    }

    .iframe iframe {
        @apply w-full border-0 mx-auto;
    }
}
